import { gql } from "@apollo/client";
import { Intent, PopoverInteractionKind } from "@blueprintjs/core";
import {
  CollectionId,
  HexType,
  OrgRole,
  ProjectLanguage,
  SpecialVersionType,
  isOrgRoleSuperset,
} from "@hex/common";
import { saveAs } from "file-saver";
import { Base64 } from "js-base64";
import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router";

import {
  HexMenu,
  HexMenuDivider,
  HexMenuItem,
  HexTooltip,
} from "../../../hex-components";
import { useCurrentUser } from "../../../hooks/me/useCurrentUser";
import { useExportProject } from "../../../hooks/useExportProject";
import { useOnClickProps } from "../../../hooks/useOnClickProps.js";
import { useTerminology } from "../../../hooks/useTerminology.js";
import { useToggleState } from "../../../hooks/useToggleState.js";
import { useClearOutputsForAppSessionMutation } from "../../../mutations/appSession.generated";
import { useDuplicateHexMutation } from "../../../mutations/hex.generated";
import { ORG_ID } from "../../../orgs";
import { Routes } from "../../../route/routes";
import { CyData } from "../../../util/cypress";
import { useDialog } from "../../../util/dialogs.js";
import { useEnforceProjectLimit } from "../../../util/useEnforceProjectLimit";
import { useExportIpynbMutation } from "../../app/HexOptionsMenu.generated";
import {
  ArchiveMenuItem,
  MenuItemWithTooltip,
} from "../../app/HexOptionsMenu.js";
import { TransferProjectOwnerDialog } from "../../app/TransferProjectOwnerDialog.js";
import { ContactAnAdmin } from "../../common/ContactAnAdmin";
import { useToaster } from "../../common/Toasts";
import { FeatureGateToolTip } from "../../feature-gate/FeatureGateToolTip";
import { HexRowOptionsMenuFragment } from "../../hex-list/HexRowOptionsMenu.generated.js";
import { CollectionHexLinks2Document } from "../../home/collections-tab/collection-show/CollectionContent2.generated";
import { GetCollectionForShowTab2Document } from "../../home/collections-tab/collection-show/CollectionShowTab2.generated";
import {
  COLLECTION_HEX_LINKS_PER_REQUEST,
  DEFAULT_COLLECTION_HEX_LINK_SORT_ORDER,
} from "../../home/collections-tab/collectionConstants";
import {
  ArrowTopRightIcon,
  CollectionIcon,
  DuplicateIcon,
  EditIcon,
  EyeOpenIcon,
  LinkIcon,
  RemoveIcon,
  TransferOwnerIcon,
  TrashIcon,
  UploadIcon,
  WarningIcon,
} from "../../icons/CustomIcons";

import {
  useRemoveHexFromCollection2Mutation,
  useSetHexArchived2Mutation,
} from "./HexRowOptionsMenuContents.generated.js";
import {
  getConfirmAddToCollectionHexDialogId,
  getConfirmDeleteHexDialogId,
} from "./utils.js";

gql`
  mutation RemoveHexFromCollection2(
    $collectionId: CollectionId!
    $hexId: HexId!
  ) {
    removeProjectFromCollection(collectionId: $collectionId, hexId: $hexId) {
      id
    }
  }

  mutation SetHexArchived2($hexId: HexId!, $archived: Boolean!) {
    setHexArchived(hexId: $hexId, archived: $archived) {
      id
      archivedDate
    }
  }
`;

export interface HexRowOptionsMenuContentsProps
  extends Pick<
    HexRowOptionsMenuFragment,
    | "archivedDate"
    | "unarchivedDate"
    | "canDelete"
    | "canEdit"
    | "canViewLogic"
    | "currentDraft"
    | "hexType"
    | "id"
    | "lastPublishedVersion"
    | "projectLanguage"
    | "canSetOwner"
    | "owner"
  > {
  href: string;
  canRemoveFromCollection: boolean;
  // support Projects table row updates
  onSuccessRemoveTableRow?: () => void;
  // @TODO(WORK-1172): optional click handler for menu component to facillitate conditionally calling `stopPropagation`
  onMenuClick?: React.MouseEventHandler;
}

export const HexRowOptionsMenuContents: React.FunctionComponent<HexRowOptionsMenuContentsProps> =
  React.memo(function HexRowOptionsMenuContents({
    archivedDate,
    canDelete,
    canEdit,
    canRemoveFromCollection,
    canSetOwner: userCanEditProjectOwner,
    canViewLogic,
    currentDraft,
    hexType,
    href,
    id: hexId,
    lastPublishedVersion,
    onMenuClick,
    onSuccessRemoveTableRow,
    owner,
    projectLanguage,
    unarchivedDate,
  }) {
    const canSetOwner = userCanEditProjectOwner;
    const { openDialog: openConfirmDeleteHexDialog } = useDialog(
      getConfirmDeleteHexDialogId(),
    );

    const { openDialog: openAddToCollectionDialog } = useDialog(
      getConfirmAddToCollectionHexDialogId(),
    );

    const toaster = useToaster();
    const currentUser = useCurrentUser();

    const hasOrgRoleForCollectionManagement =
      currentUser?.orgRole != null &&
      isOrgRoleSuperset(currentUser.orgRole, OrgRole.EDITOR);

    const isGuest = currentUser?.orgRole === OrgRole.GUEST;
    const isSupport = currentUser?.org.supportUserId === currentUser?.id;
    const canDuplicateAndCreateRole =
      (currentUser?.orgRole &&
        isOrgRoleSuperset(currentUser.orgRole, OrgRole.EDITOR)) ||
      (isGuest && isSupport);

    const { projectLimitEnforced } = useEnforceProjectLimit();

    const [duplicateHex] = useDuplicateHexMutation({});
    const setHexIdQueryParam = useCallback(
      (queryParams) => {
        queryParams.set("hexId", hexId);
      },
      [hexId],
    );
    const handleAddToCollectionsOnClick = useCallback(() => {
      openAddToCollectionDialog({
        beforeSetDialogQueryParam: setHexIdQueryParam,
      });
    }, [openAddToCollectionDialog, setHexIdQueryParam]);
    const handleOpenConfirmDeleteHexModalOnClick = useCallback(() => {
      openConfirmDeleteHexDialog({
        beforeSetDialogQueryParam: setHexIdQueryParam,
      });
    }, [openConfirmDeleteHexDialog, setHexIdQueryParam]);

    const openInNewTabCallback = useCallback(() => {
      window.open(href, "_blank")?.focus();
    }, [href]);

    const duplicateCallback = useCallback(async () => {
      const { data: duplicateData } = await duplicateHex({
        variables: { hexId },
      });
      const newHexId = duplicateData?.duplicateHex.id;
      const isComponent = hexType === HexType.COMPONENT;

      if (newHexId != null) {
        const url = Routes.href(
          ORG_ID,
          true,
          isComponent ? Routes.COMPONENT : Routes.LOGIC,
          {
            hexId: newHexId,
            version: SpecialVersionType.DRAFT,
          },
        );
        window.location.href = url;
      }
    }, [duplicateHex, hexId, hexType]);

    const [clearOutputs] = useClearOutputsForAppSessionMutation();
    const clearOutputsCallback = useCallback(async () => {
      if (currentDraft.logicSessionId) {
        clearOutputs({
          variables: { appSessionId: currentDraft.logicSessionId },
        })
          .then(() => {
            toaster.show({
              message: `${
                hexType === HexType.COMPONENT ? "Component" : "Project"
              } outputs cleared`,
            });
          })
          .catch((_e) => {
            toaster.show({
              intent: Intent.DANGER,
              message: `${
                hexType === HexType.COMPONENT ? "Component" : "Project"
              } outputs could not be cleared`,
            });
          });
      }
    }, [currentDraft.logicSessionId, clearOutputs, toaster, hexType]);

    const [exportIpynb] = useExportIpynbMutation();

    const exportProject = useExportProject({ hexId });

    const exportIpynbCallback = useCallback(async () => {
      if (currentDraft.id) {
        const { data: exportData } = await exportIpynb({
          variables: { hexVersionId: currentDraft.id },
        });
        if (exportData) {
          const { content, filename } = exportData.exportIpynb;
          saveAs(
            new Blob([Base64.decode(content)], { type: "application/json" }),
            filename,
          );
        }
        // TODO: show an error toast if exporting fails
      }
    }, [exportIpynb, currentDraft.id]);

    const { collectionId: collectionIdParam } = useParams<{
      collectionId: string;
    }>();
    const collectionId = collectionIdParam as CollectionId;
    const [removeFromCollectionMutation] =
      useRemoveHexFromCollection2Mutation();
    const removeFromCollection = useCallback(async () => {
      try {
        await removeFromCollectionMutation({
          variables: {
            collectionId,
            hexId,
          },
          refetchQueries: [
            // necessary refetch query to show immediate update on the HexList
            {
              query: CollectionHexLinks2Document,
              variables: {
                collectionId: collectionId,
                first: COLLECTION_HEX_LINKS_PER_REQUEST,
                order: DEFAULT_COLLECTION_HEX_LINK_SORT_ORDER,
                last: null,
                before: null,
                after: null,
              },
            },
            // necessary refetch query to ensure that the correct hex Ids are filtered out when adding new projects to the collection from dialog
            {
              query: GetCollectionForShowTab2Document,
              variables: { collectionId },
            },
          ],
        });

        onSuccessRemoveTableRow?.();
      } catch (e) {
        console.error(e);
        toaster.show({
          intent: Intent.DANGER,
          message: `Could not remove project from collection`,
        });
      }
    }, [
      collectionId,
      hexId,
      removeFromCollectionMutation,
      toaster,
      onSuccessRemoveTableRow,
    ]);

    const [archiveHex] = useSetHexArchived2Mutation();
    const toggleProjectArchived = useCallback(async () => {
      await archiveHex({
        variables: {
          hexId,
          archived: !(archivedDate != null),
        },
        update: (cache) => {
          onSuccessRemoveTableRow?.();
          cache.evict({
            id: cache.identify({ __typename: "Hex", id: hexId }),
            fieldName: "archivedDate",
          });
          cache.gc();
        },
      });
    }, [archiveHex, archivedDate, hexId, onSuccessRemoveTableRow]);

    const removeFromCollectionAction = useMemo(() => {
      if (collectionId == null) {
        return <></>;
      }
      const menuItemProps = {
        disabled: !canRemoveFromCollection,
        icon: <RemoveIcon />,
        text: "Remove from collection",
        onClick: removeFromCollection,
      };
      return (
        <HexTooltip
          content="Only collection managers can remove content"
          disabled={canRemoveFromCollection}
          targetTagName="div"
        >
          <HexMenuItem {...menuItemProps} />
        </HexTooltip>
      );
    }, [canRemoveFromCollection, collectionId, removeFromCollection]);

    const moveToTrashAction = useMemo(() => {
      const removeContentTooltipCopy =
        "Only users with 'Full access' permissions can move to trash";

      const menuItemProps = {
        disabled: !canDelete,
        icon: <TrashIcon />,
        intent: Intent.DANGER,
        text: "Move to trash",
        onClick: handleOpenConfirmDeleteHexModalOnClick,
        "data-cy": CyData.DELETE_HEX,
      };
      return (
        <HexTooltip
          content={removeContentTooltipCopy}
          disabled={canDelete}
          targetTagName="div"
        >
          <HexMenuItem {...menuItemProps} />
        </HexTooltip>
      );
    }, [canDelete, handleOpenConfirmDeleteHexModalOnClick]);

    const clearOutputsTooltipCopy = canEdit
      ? "This will clear all cell outputs from the Notebook"
      : "Only users with 'Can edit' permissions can clear cell outputs from the Notebook";
    const showCopyToClipboardToast = useCallback(
      (text: string) => {
        toaster.show({
          message: (
            <>
              Link to <strong>{text}</strong> copied to clipboard.
            </>
          ),
          timeout: 2500,
        });
      },
      [toaster],
    );
    const copyLinkToClipboard = useCallback(
      async ({ link, text }) => {
        await navigator.clipboard.writeText(link);
        showCopyToClipboardToast(text);
      },
      [showCopyToClipboardToast],
    );
    const isPublished = lastPublishedVersion != null;
    const { logicText } = useTerminology();

    const copyLinkToPublish = useCallback(async () => {
      await copyLinkToClipboard({
        link: Routes.href(ORG_ID, true, Routes.APP_LATEST, {
          hexId,
        }),
        text: `Published App`,
      });
    }, [copyLinkToClipboard, hexId]);

    const copyLinkToLogic = useCallback(async () => {
      await copyLinkToClipboard({
        link: Routes.href(
          ORG_ID,
          true,
          hexType === HexType.COMPONENT ? Routes.COMPONENT : Routes.LOGIC,
          {
            hexId,
            version: SpecialVersionType.DRAFT,
          },
        ),
        text: `${logicText} view`,
      });
    }, [copyLinkToClipboard, hexId, hexType, logicText]);

    const copyPrimaryLink = useCallback(async () => {
      canViewLogic || hexType === HexType.COMPONENT
        ? await copyLinkToLogic()
        : await copyLinkToPublish();
    }, [canViewLogic, copyLinkToLogic, copyLinkToPublish, hexType]);

    const logicViewUrl = Routes.LOGIC.getUrl({
      hexId,
      version: SpecialVersionType.DRAFT,
    });

    const onClickLogicLink = useOnClickProps({
      to: logicViewUrl,
    });

    const [
      isTransferOwnerDialogOpen,
      __,
      {
        setFalse: onCloseTransferOwnerDialog,
        setTrue: onOpenTransferOwnerDialog,
      },
    ] = useToggleState(false);

    const transferOwnerDialog =
      isTransferOwnerDialogOpen && canSetOwner ? (
        <TransferProjectOwnerDialog
          closeDialog={onCloseTransferOwnerDialog}
          currentOwner={
            owner != null
              ? {
                  id: owner.id,
                  nameOrEmail: owner?.name || owner.email,
                }
              : null
          }
          hexId={hexId}
          isComponent={hexType === HexType.COMPONENT}
        />
      ) : null;

    const isComponent = hexType === HexType.COMPONENT;

    return (
      <HexMenu onClick={onMenuClick}>
        {isPublished && canViewLogic && (
          <HexMenuItem
            icon={canEdit ? <EditIcon /> : <EyeOpenIcon />}
            text={canEdit ? "Edit draft" : "View notebook"}
            {...onClickLogicLink}
          />
        )}
        <HexMenuItem
          icon={<ArrowTopRightIcon />}
          text="Open in new tab"
          onClick={openInNewTabCallback}
        />
        {isPublished && hexType === HexType.PROJECT && canViewLogic ? (
          <HexMenuItem icon={<LinkIcon />} text="Copy link">
            <HexMenuItem
              text={`to ${logicText} view`}
              onClick={copyLinkToLogic}
            />
            <HexMenuItem text="to Published App" onClick={copyLinkToPublish} />
          </HexMenuItem>
        ) : (
          <HexMenuItem
            icon={<LinkIcon />}
            text="Copy link"
            onClick={copyPrimaryLink}
          ></HexMenuItem>
        )}
        <HexMenuDivider />
        {/* TODO(GRVTY-916): enforce feature gate limit for components as well */}
        <FeatureGateToolTip
          content="duplicate projects. Your workspace has reached its project limit"
          disabled={
            !canDuplicateAndCreateRole || isComponent || !projectLimitEnforced // only show feature gate tooltip if user would otherwise be able to duplicate
          }
          featureGate="projectLimit"
        >
          <HexMenuItem
            disabled={
              !canDuplicateAndCreateRole ||
              (hexType === HexType.PROJECT && projectLimitEnforced) ||
              !canViewLogic
            }
            icon={<DuplicateIcon />}
            text="Duplicate"
            onClick={duplicateCallback}
          />
        </FeatureGateToolTip>
        {hexType === HexType.PROJECT && canViewLogic && (
          <HexMenuItem icon={<UploadIcon />} text="Export as">
            <HexMenuItem text="Hex project (.yaml)" onClick={exportProject} />
            {projectLanguage === ProjectLanguage.PYTHON && (
              <HexMenuItem
                text="Jupyter notebook (.ipynb)"
                onClick={exportIpynbCallback}
              />
            )}
          </HexMenuItem>
        )}
        <HexTooltip content={clearOutputsTooltipCopy} targetTagName="div">
          <HexMenuItem
            disabled={!canEdit}
            icon={<WarningIcon />}
            text="Clear outputs"
            onClick={clearOutputsCallback}
          />
        </HexTooltip>
        <MenuItemWithTooltip
          icon={<TransferOwnerIcon />}
          isMenuDisabled={!canSetOwner}
          isTooltipDisabled={canSetOwner}
          text={`Transfer ${isComponent ? "component" : "project"} owner`}
          tooltipContent={`Only users with 'Full Access' permissions can transfer ${
            isComponent ? "component" : "project"
          } owners`}
          onClick={onOpenTransferOwnerDialog}
        />
        <HexTooltip
          content={
            <>
              You do not have permissions to add to a collection.{" "}
              <ContactAnAdmin /> for additional permissions.
            </>
          }
          disabled={hasOrgRoleForCollectionManagement}
          interactionKind={PopoverInteractionKind.HOVER}
        >
          <HexMenuItem
            disabled={!hasOrgRoleForCollectionManagement}
            icon={<CollectionIcon />}
            text="Add to collection"
            onClick={handleAddToCollectionsOnClick}
          />
        </HexTooltip>
        {transferOwnerDialog}
        <ArchiveMenuItem
          archivedDate={archivedDate}
          canDelete={canDelete}
          toggleProjectArchivedCallback={toggleProjectArchived}
          unarchivedDate={unarchivedDate}
        />
        {collectionId && removeFromCollectionAction}
        {moveToTrashAction}
      </HexMenu>
    );
  });
